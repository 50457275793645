<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Create Product Flag">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Flag Option</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedFlag"
              :options="optionalFlag"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Select one"
              :searchable="true"
              :custom-label="customLableFlag"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeT"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerCategory"
              :options="optionalCustomerCategory"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeCC"
              :custom-label="customLableCustomerCategory"
            />
          </div>
        </div>
        <div v-if="this.selectedFlag.flagID == 1" class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Customer Group 1</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup1"
              :options="optionalCustomerGroup1"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeCG1"
              :custom-label="customLableCustomerGroup"
            />
          </div>
        </div>
        <div v-if="this.selectedFlag.flagID == 1" class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Customer Group 2</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup2"
              :options="optionalCustomerGroup2"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeCG2"
              :custom-label="customLableCustomerGroup"
            />
          </div>
        </div>
        <div v-if="this.selectedFlag.flagID == 1" class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Customer Group 3</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup3"
              :options="optionalCustomerGroup3"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeCG3"
              :custom-label="customLableCustomerGroup"
            />
          </div>
        </div>
        <div v-if="this.selectedFlag.flagID == 2" class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Customer Group 4</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup4"
              :options="optionalCustomerGroup4"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeCG4"
              :custom-label="customLableCustomerGroup4"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Salesman Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTypeSalesman"
              :options="optionalTypeSalesman"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeWP1"
              :custom-label="customLablePosition"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Salesman Subtype</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedPosition"
              :options="optionalPosition"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeWP2"
              :custom-label="customLablePosition"
            />
          </div>
          <div class="vx-col sm:w-1/6 w-full text-right">
            <vs-button
              size="medium"
              class="mr-2 mb-1 bg-success"
              icon-pack="feather"
              icon="icon-search"
              @click="handlerSearchGetWorkPosition()"
            >
              refresh</vs-button
            >
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Valid From - To</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="validity"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ dateFormat(picker.startDate) }} -
                {{ dateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Status</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <!-- <input type="radio" id="active" value="Active" v-model="status" />
            <label for="active">Active</label>

            <input
              type="radio"
              id="inactive"
              value="Inactive"
              v-model="status"
            />
            <label for="inactive">Inactive</label> -->
            <vs-switch width="120px" color="success" v-model="status">
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Line Items</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="browseItem()"
              >Browse</vs-button
            >
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full" v-if="update">
            <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
              >Submit</vs-button
            >
            <!-- <vs-button v-if="quantityChecked != true" class="mr-3 mb-2" v-on:click="CheckQty()">CheckQty</vs-button> -->
            <!-- <vs-button color="danger" class="mr-3 mb-2" @click="CancelForm()"
              >Cancel</vs-button
            > -->
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table :data="cartProducts" stripe style="width: 100%">
              <template slot="thead">
                <vs-th>SKU</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Category</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(dt, index) in data">
                  <vs-td style="align-item-center">{{ dt.skuCode }}</vs-td>
                  <vs-td style="align-item-center">{{ dt.Name }}</vs-td>
                  <vs-td style="align-item-center">{{ dt.Categories }}</vs-td>
                  <vs-td style="align-item-center">
                    <feather-icon
                      title="Delete"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 text-danger stroke-current"
                      class="ml-2"
                      @click="deleteItemLine(index)"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
      <div
        v-if="this.optionItemAvailableShow"
        class="vx-col sm:w-1/2 w-full mb-base"
      >
        <div class="vx-row mb-6">
          <h3>Item Unit</h3>
        </div>
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="items"
          :max-items="length"
          :total="total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.start }} - {{ this.end }} of {{ this.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="sku">SKU</vs-th>
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="category">Category</vs-th>
            <vs-th sort-key="action">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data.records"
            >
              <vs-td :data="data.records[indextr].sku_code">{{
                data.records[indextr].sku_code
              }}</vs-td>
              <vs-td :data="data.records[indextr].item_name"
                >{{ data.records[indextr].item_name }}
              </vs-td>
              <vs-td :data="data.records[indextr].categories_name"
                >{{ data.records[indextr].categories_name }}
              </vs-td>
              <vs-td style="align-item-center">
                <feather-icon
                  title="Add"
                  icon="PlusIcon"
                  svgClasses="w-5 h-5 text-success stroke-current"
                  class="ml-2"
                  @click="addItemLine(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";

import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    Datepicker,
    vSelect,
    DateRangePicker,
    Multiselect,
  },
  mounted() {
    this.update = true;
    this.getTerritory();
    // this.getPosition();
    // this.getSalesChannel();
    this.getTypeSalesman();
    this.getCustomerCategory();
    this.getCustomerGroup1();
    this.getCustomerGroup2();
    this.getCustomerGroup3();
    this.getCustomerGroup4();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    search: function () {
      if (this.optionItemAvailableShow) {
        this.getOptionItemAvailable();
      }
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,

    // itemsCode: [],
    // itemsName: [],
    // itemID: [],
    // itemCategory: [],
    // itemUnit: [],
    cartProducts: [],
    itemUnitID: [],

    optionItemAvailable: [{}],

    optionalPositionShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,

    items: [],
    optionTerritory: [],
    selectedTerritory: [{ name: "ALL", code: "", id: 0 }],
    optionalPosition: [],
    selectedPosition: [{ Name: "ALL", id: 0 }],
    optionalTypeSalesman: [],
    selectedTypeSalesman: [{ Name: "ALL", code: "", id: 0 }],
    // selectedSalesChannel: {},
    // optionalSalesChannel: [],
    selectedCustomerCategory: [{ name: "ALL", classification: "", id: 0 }],
    optionalCustomerCategory: [],
    selectedCustomerGroup1: [{ name: "ALL", code: "", id: 0 }],
    optionalCustomerGroup1: [],
    selectedCustomerGroup2: [{ name: "ALL", code: "", id: 0 }],
    optionalCustomerGroup2: [],
    selectedCustomerGroup3: [{ name: "ALL", code: "", id: 0 }],
    optionalCustomerGroup3: [],
    selectedCustomerGroup4: [{ name: "ALL", code: "", id: 0 }],
    optionalCustomerGroup4: [],
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    code: "",
    CompanyID: 0,
    flag: 2,
    selectedFlag: { name: "Product Focus", flagID: 1 },
    optionalFlag: [
      { name: "Product Focus", flagID: 1 },
      { name: "New Product", flagID: 2 },
    ],
    status: true,
    validity: {
      startDate: null,
      endDate: null,
    },
    minValidFrom: null,
    maxValidTo: null,
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "product-flag",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;
      this.disableTerritory = false;

      this.selectTerritory = [];
      this.selectedPosition = [];
      // this.selectedTypeSalesman = [];

      this.Input = this.resetReservation;

      // this.itemsCode = [];
      // this.itemsName = [];
      // this.itemID = [];
      // this.itemCategory = [];
      // this.itemUnit = [];

      this.cartProducts = [];
      this.itemUnitID = [];

      this.itemsUom = [];
      this.itemsQuantity = [];

      this.optionItemAvailable = [{}];

      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },
    browseItem() {
      this.search = "";
      this.optionItemAvailableShow = true;
      this.optionalPositionShow = false;
      this.getOptionItemAvailable();
      this.scrollToTop();
    },
    addItemLine(item) {
      this.quantityChecked = false;
      let check = this.itemUnitID.filter((val) => {
        return item.id == val;
      });
      console.log(check);

      if (check.length == 0) {
        this.cartProducts.push({
          itemId: item.id,
          skuCode: item.sku_code,
          Name: item.item_name,
          Categories: item.categories_name,
        });
        this.itemUnitID.push(item.id);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line ",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
      this.disableTerritory = true;
    },
    deleteItemLine(index) {
      this.quantityChecked = false;
      if (this.external == false) {
        this.itemsCode.splice(index, 1);
        this.itemsName.splice(index, 1);
        this.itemID.splice(index, 1);
        this.itemCategory.splice(index, 1);
        this.itemID.length == 0;
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleChangelength(val) {
      this.length = val == "All" ? this.total : val;
      this.page = 1;
      this.getOptionItemAvailable();
    },
    getOptionItemAvailable(page = 1) {
      this.isActive = page;

      this.$http
        .get("/api/sfa/v1/product-flag/table", {
          params: {
            // territory_id: this.selectedTerritory.id,
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          console.log("RRRRRRR", resp);
          if (resp.code == 200) {
            let company_id = this.$store.state.user.currentUser.company_id;
            this.optionItemAvailable = resp.data.ItemsAvailable;
            this.total = resp.data.total_record;
            this.totalPage = resp.data.total_page;
            this.totalSearch = resp.data.total_record_search;
            this.length = resp.data.total_record_per_page;
            this.items = resp.data;
            this.CompanyID = company_id;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    SubmitForm() {
      if (this.cartProducts.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      let TerritoryIDs = [];
      this.selectedTerritory.forEach((element) => {
        TerritoryIDs.push(element.id);
      });
      let WorkPositionIDs = [];
      this.selectedPosition.forEach((element) => {
        WorkPositionIDs.push(element.id);
      });
      let TypeSalesmanIDs = [];
      this.selectedTypeSalesman.forEach((element) => {
        TypeSalesmanIDs.push(element.id);
      });
      let CustomerCategoryIDs = [];
      this.selectedCustomerCategory.forEach((element) => {
        CustomerCategoryIDs.push(element.id);
      });
      let CustomerGr1IDs = [];
      this.selectedCustomerGroup1.forEach((element) => {
        CustomerGr1IDs.push(element.id);
      });
      let CustomerGr2IDs = [];
      this.selectedCustomerGroup2.forEach((element) => {
        CustomerGr2IDs.push(element.id);
      });
      let CustomerGr3IDs = [];
      this.selectedCustomerGroup3.forEach((element) => {
        CustomerGr3IDs.push(element.id);
      });
      let CustomerGr4IDs = [];
      this.selectedCustomerGroup4.forEach((element) => {
        CustomerGr4IDs.push(element.id);
      });

      this.$vs.loading();
      this.$http
        .post("/api/sfa/v1/product-flag/store", {
          territory_id: TerritoryIDs,
          work_position_id: WorkPositionIDs,
          type_salesman_id: TypeSalesmanIDs,
          customer_category_id: CustomerCategoryIDs,
          // sales_channel_id: this.selectedSalesChannel.id,
          customer_group_id_1: CustomerGr1IDs,
          customer_group_id_2: CustomerGr2IDs,
          customer_group_id_3: CustomerGr3IDs,
          customer_group_id_4: CustomerGr4IDs,
          company_id: this.CompanyID,
          flag: this.selectedFlag.flagID,
          item_id: this.cartProducts,
          status: this.status
            ? this.status == true
              ? "Active"
              : "Inactive"
            : "Inactive",
          valid_from: this.validity.startDate
            ? moment(this.validity.startDate).format("YYYY-MM-DD")
            : null,
          valid_to: this.validity.endDate
            ? moment(this.validity.endDate).format("YYYY-MM-DD")
            : null,
        })
        .then((resp) => {
          console.log("ccccccc", resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleBack();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    getTypeSalesman() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-flag/type-salesman", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
            search: this.searchwp1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalTypeSalesman = [{ Name: "ALL", code: "", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionalTypeSalesman.push(resp.data.records[index]);
              }
              // this.optionalTypeSalesman = resp.data.records;
              if (this.optionalTypeSalesman.length > 0) {
                // this.selectedTypeSalesman = this.optionalTypeSalesman[0];
              } else {
                this.optionalTypeSalesman = [];
                this.selectedTypeSalesman = [];
              }
            } else {
              this.optionalTypeSalesman = [];
              this.selectedTypeSalesman = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
          console.log(this.selectedTypeSalesman);
        });
    },
    getPosition() {
      this.$vs.loading();
      console.log(this.selectedTypeSalesman);
      this.optionWorkPosition = [];

      let TypeSalesmanIDs = [];
      this.selectedTypeSalesman.forEach((element) => {
        if (element.id != 0) {
          TypeSalesmanIDs.push(element.id);
        }
      });
      this.$http
        .get("/api/sfa/v1/product-flag/work-position", {
          params: {
            length: 9999,
            order: "name",
            sort: "asc",
            search: this.searchwp2,
            sales_type: TypeSalesmanIDs,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalPosition = [{ Name: "ALL", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionalPosition.push(resp.data.records[index]);
              }
              // this.optionalPosition = resp.data.records;
              if (this.optionalPosition.length > 0) {
                // this.selectedPosition = this.optionalPosition[0];
              } else {
                this.optionalPosition = [];
                this.selectedPosition = [];
              }
            } else {
              this.optionalPosition = [];
              this.selectedPosition = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerCategory() {
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-flag/customer-category", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
            search: this.searchCC,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalCustomerCategory = [
                { name: "ALL", classification: "", id: 0 },
              ];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionalCustomerCategory.push(resp.data.records[index]);
              }
              // this.optionalCustomerCategory = resp.data.records;
              if (this.optionalCustomerCategory.length > 0) {
                // this.selectedCustomerCategory =
                //   this.optionalCustomerCategory[0];
              } else {
                this.optionalCustomerCategory = [];
                this.selectedCustomerCategory = {};
              }
            } else {
              this.optionalCustomerCategory = [];
              this.selectedCustomerCategory = {};
            }
            // this.$vs.loading.close();
          } else {
            // this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup1() {
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-flag/customer-group", {
          params: {
            level: "1",
            length: 0,
            search: this.searchCG1,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalCustomerGroup1 = [{ name: "ALL", code: "", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionalCustomerGroup1.push(resp.data.records[index]);
              }
              // this.optionalCustomerGroup1 = resp.data.records;
              if (this.optionalCustomerGroup1.length > 0) {
                // this.selectedCustomerGroup1 = this.optionalCustomerGroup1[0];
              } else {
                this.optionalCustomerGroup1 = [];
                this.selectedCustomerGroup1 = {};
              }
            } else {
              this.optionalCustomerGroup1 = [];
              this.selectedCustomerGroup1 = {};
            }
            // this.$vs.loading.close();
          } else {
            // this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup2() {
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-flag/customer-group", {
          params: {
            level: "2",
            length: 0,
            search: this.searchCG2,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalCustomerGroup2 = [{ name: "ALL", code: "", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionalCustomerGroup2.push(resp.data.records[index]);
              }
              // this.optionalCustomerGroup2 = resp.data.records;
              if (this.optionalCustomerGroup2.length > 0) {
                // this.selectedCustomerGroup2 = this.optionalCustomerGroup2[0];
              } else {
                this.optionalCustomerGroup2 = [];
                this.selectedCustomerGroup2 = {};
              }
            } else {
              this.optionalCustomerGroup2 = [];
              this.selectedCustomerGroup2 = {};
            }
            // this.$vs.loading.close();
          } else {
            // this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup3() {
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-flag/customer-group", {
          params: {
            level: "3",
            length: 0,
            search: this.searchCG3,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalCustomerGroup3 = [{ name: "ALL", code: "", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionalCustomerGroup3.push(resp.data.records[index]);
              }
              // this.optionalCustomerGroup3 = resp.data.records;
              if (this.optionalCustomerGroup3.length > 0) {
                // this.selectedCustomerGroup3 = this.optionalCustomerGroup3[0];
              } else {
                this.optionalCustomerGroup3 = [];
                this.selectedCustomerGroup3 = {};
              }
            } else {
              this.optionalCustomerGroup3 = [];
              this.selectedCustomerGroup3 = {};
            }
            // this.$vs.loading.close();
          } else {
            // this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup4() {
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-flag/customer-group4", {
          params: {
            length: 0,
            order: "name",
            search: this.searchCG4,
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalCustomerGroup4 = [{ name: "ALL", code: "", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionalCustomerGroup4.push(resp.data.records[index]);
              }
              // this.optionalCustomerGroup4 = resp.data.records;
              if (this.optionalCustomerGroup4.length > 0) {
                // this.selectedCustomerGroup4 = this.optionalCustomerGroup4[0];
              } else {
                this.optionalCustomerGroup4 = [];
                this.selectedCustomerGroup4 = {};
              }
            } else {
              this.optionalCustomerGroup4 = [];
              this.selectedCustomerGroup4 = {};
            }
            // this.$vs.loading.close();
          } else {
            // this.$vs.loading.close();
          }
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/territory", {
          params: {
            // length: 0,
            // order: "name",
            // sort: "asc",
            search: this.searchT,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = [{ name: "ALL", code: "", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTerritory.push(resp.data.records[index]);
              }
              // this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                // this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomerCategory({ name, classification }) {
      return `${name} ${classification}`;
    },
    customLableSalesChannel({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomerGroup({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomerGroup4({ code, name }) {
      return `${code} ${name}`;
    },
    customLablePosition({ Name }) {
      return `${Name}`;
    },
    customLableFlag({ name }) {
      return `${name}`;
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },
    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getOptionItemAvailable(this.isActive);
    },
    handleChangePage(page) {
      this.page = page;
      this.getOptionItemAvailable(this.isActive);
    },
    handleSort(key, active) {
      this.order = key;
      this.sort = active;
      this.getOptionItemAvailable(this.isActive);
    },
    handlerSearchChangeWP1(searching) {
      this.optionalTypeSalesman = [];
      this.searchwp1 = searching;
      this.getTypeSalesman();
    },
    handlerSearchChangeWP2(searching) {
      this.searchwp2 = searching;
      this.getPosition();
    },
    handlerSearchChangeCC(searching) {
      this.optionalCustomerCategory = [];
      this.searchCC = searching;
      this.getCustomerCategory();
    },
    handlerSearchChangeCG4(searching) {
      this.optionalCustomerGroup4 = [];
      this.searchCG4 = searching;
      this.getCustomerGroup4();
    },
    handlerSearchChangeCG3(searching) {
      this.optionalCustomerGroup3 = [];
      this.searchCG3 = searching;
      this.getCustomerGroup3();
    },
    handlerSearchChangeCG2(searching) {
      this.optionalCustomerGroup2 = [];
      this.searchCG2 = searching;
      this.getCustomerGroup2();
    },
    handlerSearchChangeCG1(searching) {
      this.optionalCustomerGroup1 = [];
      this.searchCG1 = searching;
      this.getCustomerGroup1();
    },
    handlerSearchGetWorkPosition(data) {
      console.log(this.selectedTypeSalesman);
      this.getPosition();
    },
    handlerSearchChangeT(searching) {
      this.optionTerritory = [];
      this.searchT = searching;
      this.getTerritory();
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
  },
};
</script>
